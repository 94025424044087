

@font-face{
  src: url("./assets/fonts/consolaz.ttf");
  font-family: "Consolaz";
}

.font-consolaz{
  font-family: "Consolaz" !important;
}

html {
  scroll-behavior: smooth;
  
}

nav.navbar {
    background-image: url("./assets/bg.svg");
    height: 92px;
    background-color: #f59a79;
    /* display: flex; */
    align-items: center;
    justify-content: space-between;
}
.menu-btn{
    width: 28px;
    height: 2px;
    background-color: #fff;
}
.menu-btn::before{
    content: "";
    width: 20px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    top: -8px;
}
.menu-btn::after{
    content: "";
    width: 16px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    top: 8px;
}

div.hero-section {
    display: grid;
    background-image: url("./assets/bg.svg");
    background-color: #f1f1f1;
    grid-template-columns: 46% 54%;
    /* height: calc(100vh - 40px); */
}

div.hero-left-section {
    background-image: url("./assets/hero-bg.svg");
    background-size: contain;
    background-repeat: no-repeat;
    height: auto;
    width: 100%;
    background-position: 0 0;
}

div.hero-right-section {
    /* display: flex; */
    justify-content: center;
    align-items: center;
}

/* div.hero-right-section img {
    width: 100%;
} */

div.hero-right-section::before {
    content: "";
    background-image: url("./assets/hero-shadow.svg");
    position: absolute;
    right: 30%;
    bottom: -54px;
}

.nav a {
    color: #ffffff;
    text-decoration: none;
}

.nav-btn {
    display: flex;
    align-items: center;
    gap: 16px;
}

.nav-btn .login-btn {
    color: white;
    background-color: transparent;
    border: none;
    outline: none;
}

.nav-btn .register-btn {
    color: #FF6700;
    background-color: #ffffff;
    border-radius: 4px;
    height: 56px;
    border: none;
    outline: none;
    padding: 15px 29px;
}

.trust-section {
    height: 166.98px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("./assets/bg.svg");
    background-color: #fff;
}

.trusted-brands {
    display: flex;
    gap: 16px;
    margin-top: 24px;
}

.trust-section p {
    position: relative;
}

.trust-section p::before {
    content: "";
    height: 4px;
    width: 56px;
    background-color: #D3D3D3;
    position: absolute;
    left: 50%;
    top: 36px;
    margin-left: -28px;
}

.trusted-brands img:nth-child(2) {
    margin-left: 22px;
}

.trusted-brands img {
    height: 58.28px;
}

.hero-title {
    align-items: center;
    font-size: 3rem;
}

.hero-title span {
    position: relative;
    display: inline-block;
}

.hero-title span::before {
    content: "";
    background-image: url("./assets/hero-intro.svg");
    background-repeat: no-repeat;
    height: 120px;
    display: block;
    width: 285px;
    position: absolute;
    top: -35px;
    left: -16px;
}

.hero-title+p {
    /* font-size: 22px; */
    font-size: 1.35rem;
}
.btn-container{
    display: flex;
    gap: 12px;
}
.btn-container .btn-register2{
    background-color: #FF6700;
    outline: none;
    border: none;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 15px 20px;
    color: white;
}
.quote-mark{
    background-image: url("./assets/quotes.svg");
    background-repeat: no-repeat;
    background-position: 10% 5%;
}
.swiper{
    padding-left: 40px;
    padding-right: 40px;
}
.swiper-button-next{
    background-color: #FF6700;
    border-radius: 32px;
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}
.swiper-button-prev{
    border-radius: 32px;
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EFEFEF;
    color: #D3D3D3;
}
.swiper-button-prev::after,
.swiper-button-next::after{
    font-size: 24px;
}
.btn-container .explore-btn{
    background-color: white;
    outline: none;
    border: none;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 15px 20px;
    color: #FF6700;
}
.product-features2{
    background-color: #F4F7FA;
}
.testimonial{
    background-image: url("./assets/testimonials-bg.svg");
    background-repeat: no-repeat;
    background-position: 4em -4em;
}

.download-cta-container{
    border-radius: 100px;
    /* height: 480px; */
    background-image: url("./assets/bg.svg");
}
.caution-container{
    background-image: url("./assets/bg.svg");
}
.accordion {
    background-color: #FF6700;
    color: #ffffff;
    font-weight: 700;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
    border-bottom: 4px solid white;
  }
  
  .homepage .active, .accordion:hover {
    background-color: #d6592b;
  }
  
  .accordion:after {
    content: '\002B';
    color: #ffffff;
    font-weight: bold;
    float: right;
    margin-left: 5px;
  }
  
  .panel {
    padding: 0 18px;
    background-color: #FFF4F0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }

textarea:focus{
    outline: none;
}









.checkbox-wrapper {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
}
.checkbox-wrapper:hover input ~ .checkmark {
  background-color: #ccc;
}
.checkbox-wrapper input:checked ~ .checkmark {
  background-color: #FF6700;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox-wrapper input:checked ~ .checkmark:after {
  display: block;
}
.checkbox-wrapper .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}























.onboarding-background{
    background-image: url("./assets/bg.svg");
    padding-top: 45px;
    padding-bottom: 45px;
    min-height: 100vh;
}
.onboarding-form-container{
    width: 459px;
    min-height: 403px;
    border: 1px solid #E0E0E0;
    border-top: 8px solid #FF6700;
    border-radius: 4px;
}
.input-wrapper input{
    color: #828282;
    font-size: 14px;
}
.css-yk16xz-control{
    color: red !important;
    border-radius: 0 !important;
    border: 1.5px solid #C4C4C4 !important;
    height: 48px;
    outline: none;
}
.css-1pahdxg-control{
    border-radius: 0 !important;
    box-shadow: none !important;
    border: none !important;
    border-bottom: 1.5px solid #FF6700 !important;
}
.input-wrapper .input:invalid,
.input-wrapper .input{
    border-bottom: 1.5px solid #C4C4C4;
}
.input-wrapper .input:valid,
.input-wrapper .input:focus,
.css-yk16xz-control:focus{
    border-bottom: 1.5px solid #FF6700 !important;
}
.indicator{
    background-color: #e0e0e0;
}
.indicator.checked{
    background-color: #FF6700;
}
.react-tel-input .flag-dropdown{
    background-color: transparent;
    /* border: none; */
}
.react-tel-input .form-control{
    width: 100%;
    border: none;
    height: 48px;
    border: 1.5px solid #C4C4C4;
    border-radius: 0;
}
.react-tel-input{
    margin-top: 2px;
}

@media screen and (max-width: 1100px){
    div.hero-right-section{
        padding: 0 12px;
    }
    .hero-title{
        font-size: 42px;
    }
    .hero-title span::before {
        content: "";
        background-image: url("./assets/hero-intro.svg");
        background-repeat: no-repeat;
        height: 120px;
        width: 285px;
        position: absolute;
        bottom: 0px;
        left: 0;
    }
    div.hero-section{
        grid-template-columns: 65% 35%;
    }
}



@media screen and (max-width: 765px){
    div.hero-section {
        display: grid;
        background-image: url("./assets/bg.svg") url("./assets/hero-bg.svg");
        background-color: #FAFAFA;
        grid-template-columns: 1fr;
        height: calc(78vh - 40px);
    }
    .hero-title span::before{
        display: none;
    }
    .hero-title{
        font-size: 40px;
    }
    .hero-right-section{
        display: none;
    }

    .trust-section{
        height: auto;
        padding-top: 24px;
        padding-bottom: 24px;
    }
    .trusted-brands{
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    /* .swiper-slide,
    .swiper-wrapper,
    .swiper{
        max-width: 100% !important;
        margin: 0 !important;
    } */
    .download-cta-container{
        height: auto;
    }
}


/* Mobile screens */
@media screen and (max-width: 640px){
    .hero-title{
        font-size: 38px;
    }
    div.hero-section{
        height: auto;
        padding-bottom: 32px;
        background-color: #FFF8F5;
    }
    div.hero-left-section {
        background-image: url("./assets/hero-bg.svg");
        background-size: 30%;
        height: auto;
        background-repeat: no-repeat;
        padding-top: 64px;
        width: 100%;
        background-position: 0 0;
    }
    .trust-section p{
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        margin-top: 16px;
    }
    .trust-section p::before{
        top: 64px;
        background-color: #222222;
    }
    .trust-section{
        background-color: white;
    }
    .trusted-brands img{
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .title{
        font-size: 24px;
        font-weight: 700;
    }
    .title2{
        font-size: 34px;
    }
    .swiper-button-next{
        background-color: var(--primary-theme);
        color: white;
        width: 54px;
        height: 54px;
        border-radius: 42px;
        box-shadow: 2px 2px 4px lightgray;
        border: 1px solid lightgray;
    }
    .swiper-button-prev{
        background-color: #ebebeb;
        color: #000000;
        width: 54px;
        height: 54px;
        border-radius: 42px;
        box-shadow: 2px 2px 4px lightgray;
        border: 1px solid lightgray;
    }

    .swiper-button-prev::after,
    .swiper-button-next::after{
        font-size: 20px;
    }

    .btn-container .explore-btn2{
        background-color: white;
        outline: none;
        border: none;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        color: #FF6700;
    }
}

.bg-asset{
  background: linear-gradient(#fafafa90 ,#dddddd80) center center, 
  url("./assets/bg.png") no-repeat;
}






































.spinner {
    -webkit-animation: rotator 1.4s linear infinite;
            animation: rotator 1.4s linear infinite;
  }
  
  @-webkit-keyframes rotator {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
    }
  }
  
  @keyframes rotator {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
    }
  }
  .path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    -webkit-transform-origin: center;
        -ms-transform-origin: center;
            transform-origin: center;
    -webkit-animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
            animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
  }
  
  @-webkit-keyframes colors {
    0% {
      stroke: red;
    }
    14.285714286% {
      stroke: orange;
    }
    28.571428572% {
      stroke: yellow;
    }
    42.857142858% {
      stroke: green;
    }
    57.142857144% {
      stroke: blue;
    }
    71.42857143% {
      stroke: indigo;
    }
    100% {
      stroke: violet;
    }
  }
  
  @keyframes colors {
    0% {
      stroke: red;
    }
    14.285714286% {
      stroke: orange;
    }
    28.571428572% {
      stroke: yellow;
    }
    42.857142858% {
      stroke: green;
    }
    57.142857144% {
      stroke: blue;
    }
    71.42857143% {
      stroke: indigo;
    }
    100% {
      stroke: violet;
    }
  }
  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 187;
    }
    50% {
      stroke-dashoffset: 46.75;
      -webkit-transform: rotate(135deg);
              transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: 187;
      -webkit-transform: rotate(450deg);
              transform: rotate(450deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dashoffset: 187;
    }
    50% {
      stroke-dashoffset: 46.75;
      -webkit-transform: rotate(135deg);
              transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: 187;
      -webkit-transform: rotate(450deg);
              transform: rotate(450deg);
    }
  }



.documentation-container{
  width: calc(100% - 313px);
}

.documentation-sidebar{
  width: 313px;
  height: calc(100vh - 80px);
  position: sticky;
  top: 80px;
  left: 0;
}
.documentation-container .content{
  /* width: 630px; */
  width: calc(100% - 240px);
}

  .documentation-container .title{
    /* font-family: "Lato"; */
    font-size: 28px;
    height: 45px;
    font-weight: 600;
    line-height: 140%;
    color: #616161;
    display: flex;
    margin:0;
  }

  .documentation-container .paragraph{
    /* font-family: "Lato"; */
    font-size: 15px;
    font-weight: 400;
    line-height: 140%;
    color: #616161;
    display: flex;
    margin:0;
  }

  .editor{
    font-size: 15px;
    font-weight: 400;
    line-height: 140%;
    color: #616161;
    display: flex;
    margin:0;
    background-color: #EFEFEF;
    padding: 10px 21px;
  }

  .documentation-container .subTopic{
    font-size: 20px;
    font-weight: 500;
    line-height: 140%;
    color: #616161;
    display: flex;
    margin:0;
    margin-top: -80px;
    padding-top: 100px;
  }
  

  .documentation-container .unorderedList .listItem{
    font-size: 15px;
    font-weight: 400;
    /* line-height: 100%; */
    color: #616161;
    margin: 0;
    margin-left: 24px;
  }